let laConfig = null

























const serverProduction = {    
    titulo:"Virtual Blix App",
    baseURL:"https://www.blixstore.virtualblix.com/api/",
    urlSignalR: "https://www.blixcentral.virtualblix.com/iapushHub"        
};
laConfig = serverProduction;

export const ServerConfig = laConfig;